<template>
  <metainfo />
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <Suspense>
        <component :is="Component" />
      </Suspense>
    </template>
  </RouterView>
</template>

<script>
import TheNav from '@/components/TheNav.vue'
import TheFooter from '@/components/TheFooter.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'HomeView',
  components: {
    TheNav, TheFooter
  },
  setup () {
    const router = useRouter()

    router.beforeEach((to, from, next) => {
      scrollTop()
      next()
    })

    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return {
    }
  }

}
</script>

<style lang="scss" module>

</style>

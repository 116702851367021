import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('../views/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'FullLayout',
    component: () => import('../views/FullLayout.vue'),
    children: [
      {
        path: '/cookie',
        name: 'cookie',
        components: {
          default: () => import('../views/CookieView.vue'),
          footer: () => import('../components/TheFooter.vue')
        }
      },
      {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        components: {
          default: () => import('../views/PrivacyPolicy.vue'),
          footer: () => import('../components/TheFooter.vue')
        }
      },
      {
        path: '/disclaimer',
        name: 'disclaimer',
        components: {
          default: () => import('../views/DisclaimerView.vue'),
          footer: () => import('../components/TheFooter.vue')
        }
      },
      {
        path: '/not-found',
        name: 'notFound',
        component: () => import('../views/NotFound.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import('../views/NotFound.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
